import { useStaticQuery, graphql } from 'gatsby'

const useCategoryQuery = () => {
  const queryResult = useStaticQuery(graphql`
    query ShopByCategorySliderQuery {
      jewelryProducts: jewelryTypes {
        id
        name
        urlPath
        productsInStock
        categoryImages {
          gatsbyImageDataMock
        }
      }
      watchBrands: watchBrandsCategories {
        id
        name
        urlPath
        productsInStock
        categoryImage: brandImage {
          gatsbyImageDataMock
        }
      }
    }
  `)
  let usedImages = []
  queryResult.jewelryProducts
    .map((category, index) => ({
      index,
      category,
      imageCount: category.categoryImages.length,
    }))
    .sort((a, b) => a.imageCount - b.imageCount)
    .forEach(tempItem => {
      let category = tempItem.category
      let images =
        category.categoryImages.filter(
          image => !usedImages.includes(image.gatsbyImageDataMock.url)
        ) || []
      if (images.length > 0) {
        queryResult.jewelryProducts[tempItem.index].categoryImage = images[0]
        usedImages.push(images[0].gatsbyImageDataMock.url)
      }
    })
  return queryResult
}

export default useCategoryQuery
