import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import NewArrivalSlider from '../components/sliders/NewArrivalSlider'
import Seo from '../components/Seo'
import LatestBlogs from '../components/LatestBlogs'
import ShopByCategory from '../components/form/ShopByCategory'

const PageNotFound404 = () => {
  return (
    <Layout className={'container mx-auto'} breadcrumbs={false}>
      <Seo title="404" noindex={true} />
      <div className="w-full flex flex-col md:flex-row max-w-[1366px] mx-auto p-5 ">
        <div className="max-w-max mx-auto w-full md:w-1/2 flex flex-col justify-center items-start gap-5 order-2 md:order-1 ">
          <h2 className="text-5xl font-bold text-red-700">
            OOPS....
            <br />
            <span className="text-2xl sm:text-3xl"> This page needs a repair!</span>
          </h2>
          <div className="max-w-max mx-auto md:mx-0 md:w-1/2 lg:w-full ">
            <b>We couldn't find a match for your page!</b>
            <br />
            Try these helpful tips:
            <br />
            <p className="text-gray-500 text-wrap">
              <span className="text-green-600 inline-block">&#10003;</span> Browse our menu options.
            </p>
            <p className="text-gray-500">
              <span className="text-green-600 inline-block">&#10003;</span> Click on a product or
              category image below.
            </p>
            <p className="text-gray-500">
              <span className="text-green-600 inline-block">&#10003;</span> Click one of the
              suggested links.
            </p>
            <br />
            Need additional help?
            <Link to="/contact" className="text-red-600 mb-5 block">
              Contact us
            </Link>
          </div>
          Suggested Links:
          <div className="md:w-1/2 lg:w-full columns-2 sm:columns-auto">
            <div className="flex flex-row flex-wrap gap-3 text-sm py-1">
              <Link
                to="/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Home Page
              </Link>
              <Link
                to="/fine-watches/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Luxury Watches
              </Link>
              <Link
                to="/designer-jewelry/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Designer Jewelry
              </Link>
              <Link
                to="/estate-jewelry-and-vintage-jewelry/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Estate Jewelry
              </Link>
              <Link
                to="/accessories/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Accessories
              </Link>
              <Link
                to="/digital-catalog/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Digital Catalog
              </Link>
              <Link
                to="/repairs/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Watch Repairs
              </Link>
              <Link
                to="/jewelry-repair/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Jewelry Repairs
              </Link>
              <Link
                to="/blog/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                Latest Blogs
              </Link>
              <Link
                to="/about-us/"
                className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
              >
                About Us
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center md:w-1/2 max-w-[500px] order-1 md:order-2 mx-auto">
          <StaticImage
            alt="This page is Not Found"
            src="../images/404-page-not-found.jpg"
            className=""
          />
        </div>
      </div>
      <hr className="w-9/12 mx-auto" />
      <NewArrivalSlider />
      <hr className="w-9/12 mx-auto" />
      <ShopByCategory />
      <hr className="w-9/12 mx-auto" />
      <LatestBlogs />
    </Layout>
  )
}

export default PageNotFound404
