import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BlogsSlider from './sliders/BlogsSlider'
import { gridSizes, processGatsbyImageDataMock } from '../common/gatsbyImageData'

const LatestBlogs = () => {
  const {
    recentBlogs: { blogs },
  } = useStaticQuery(graphql`
    {
      recentBlogs: allStrapiBlogPost(limit: 10, sort: { order: DESC, fields: publishedAt }) {
        blogs: nodes {
          title
          heroImage {
            gatsbyImageDataMock
          }
          seo {
            metaDescription
          }
          url: urlPath
        }
      }
    }
  `)

  blogs.forEach(blog => {
    processGatsbyImageDataMock(blog.heroImage, gridSizes)
  })
  return (
    <section className="flex flex-col">
      <BlogsSlider blogs={blogs} h2Title="Latest Watch Blogs and Jewelry Articles" />
      <Link className="red-raised-btn px-5 py-3 max-w-max mx-auto" to="/blog/">
        <h3 className="uppercase"> BROWSE ALL BLOGS & ARTICLES</h3>
      </Link>
    </section>
  )
}

export default LatestBlogs
