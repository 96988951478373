import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import useCategoryQuery from '../../hooks/useCategoryQuery'
import useCDNImage from '../../hooks/useCDNImage'
import { gridSizes } from '../../common/gatsbyImageData'
import IconLoaders from '../../icons/IconLoaders'

const ProductCard = ({ title, image, url, type = 'watch' }) => {
  const productType = type === 'watch' ? 'Mens Watch' : 'bracelet'
  const finalImage = useCDNImage(image, { productType }, gridSizes, '10em')
  return (
    <Link to={url} className="flex flex-col items-center group">
      <div className="aspect-square bg-gray-100 w-full flex justify-center items-center p-3 ">
        {finalImage ? (
          <GatsbyImage
            image={finalImage.gatsbyImageData}
            alt={title}
            className={
              'mix-blend-multiply group-hover:scale-110 duration-300 w-full h-full aspect-square'
            }
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : (
          <IconLoaders type={productType} flashing={false} />
        )}
      </div>
      <h2 className="md:uppercase text-sm my-1 mt-2 text-center h-10 flex items-center line-clamp-2">
        {title}
      </h2>
    </Link>
  )
}

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
}

const ShopByCategory = () => {
  const { watchBrands, jewelryProducts } = useCategoryQuery()

  // Sort watch brands by productsInStock and take top 8
  const topWatchBrands = watchBrands
    .sort((a, b) => b.productsInStock - a.productsInStock)
    .slice(0, 12)
  const topJewelryCategories = jewelryProducts
    .sort((a, b) => b.productsInStock - a.productsInStock)
    .slice(0, 12)

  return (
    <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 w-full max-w-[1366px] mx-auto gap-4 justify-center items-center my-5 flex-wrap">
      <h2 className="col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-6 w-full text-center text-2xl md:text-3xl font-bold mb-5">
        Shop Watches by Brand
      </h2>
      {topWatchBrands.map(brand => (
        <ProductCard
          key={brand.id}
          title={brand.name}
          url={brand.urlPath}
          image={brand.categoryImage}
          type="watch"
        />
      ))}
      <Link
        to="/all-watch-brands"
        className="col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-6 max-w-max px-4 py-2 bg-red-700 text-white hover:bg-red-600 mx-auto font-bold duration-300 mb-5"
      >
        SHOP ALL WATCH BRANDS
      </Link>
      <hr className="w-10/12 mx-auto col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-6 py-5" />
      {topJewelryCategories.map(item => (
        <ProductCard
          key={item.id}
          title={item.name}
          url={item.urlPath}
          image={item.categoryImage} // Use the first category image of the product
          type="jewelry"
        />
      ))}
      <Link
        to="/jewelry"
        className="col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-6 max-w-max px-4 py-2 bg-red-700 text-white hover:bg-red-600 mx-auto font-bold duration-300 mb-5"
      >
        SHOP ALL FINE JEWELRY
      </Link>
    </section>
  )
}

export default ShopByCategory
